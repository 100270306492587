.source-table-bg {
  background-color: rgb(227, 228, 227);
}

.table-wrapper {
  flex: 1;
  overflow-y: auto;
  border: 1px solid #aaa;
}

.table-container-2 {
  overflow-y: auto;
  max-height: 99vh;
  border: 1px solid #aaa;
}
  
.table {
  border-collapse: collapse;
  float: left;
}

.filter-container {
  position: sticky;
  top: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.filter-row,
.checkbox-row {
  display: flex;
  justify-content: space-between;
}

.checkbox-row label {
  margin: 0 10px;
}

.table-header {
  position: sticky;
  top: 0px;
  z-index: 2;
}

.clickable-header {
  cursor: pointer;
}

th {
  background-color: #aaa;
}

th,
td {
  border: 0px solid black;
  padding: 4px;
  text-align: left;
}
  
tr[draggable]:hover {
  cursor: grab;
}

tr.drop-target {
  background-color: rgb(214, 239, 247);
}

.nesting-level-0 {
  background-color: #bbb;
}

.nesting-level-1 {
  background-color: #ccc;

}

.nesting-level-2 {
  background-color: #ddd
}

.nesting-level-3 {
  background-color: #eee;
}

.nesting-level-4 {

  background-color: #fff;
}
