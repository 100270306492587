.main-container {
  display: flex;
  flex-direction: column;
}

.table-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.info-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 2em;
  cursor: pointer;
  z-index: 4;
}

.info-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.info-modal-content {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 10px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  font-size: 1.5em;
  z-index: 5;
}
